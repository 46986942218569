import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";

import Icon from "../../assets/icons/icons";
import logo from "../../assets/pandora_amp_logo.svg";
import { Charts } from "../../chart.constants";
import { isCurrentPath } from "../../services/server/util.service";
import SiteNav from "../site-nav/site-nav.component";

import styles from "./nav-header.module.scss";

const MENU_BREAKPOINT = 768;

function NavHeader() {
	function closeMobileNav() {
		setIsMobileNavOpen(false);
	}

	function onToggle() {
		setIsMobileNavOpen(!isMobileNavOpen);
	}

	function onRouteChangeStart() {
		setIsLoading(true);
	}

	function onRouteChangeComplete() {
		setIsMobileNavOpen(false);
		setTimeout(() => setIsLoading(false));
	}

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isUsingMobileNav, setIsUsingMobileNav] = useState<boolean>(false);
	const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false);
	const router = useRouter();

	useEffect(() => {
		if (!window.matchMedia) {
			return;
		}

		const mediaQuery = window.matchMedia(`(max-width: ${MENU_BREAKPOINT}px)`);
		if (!mediaQuery || !mediaQuery.addEventListener) {
			return;
		}

		function onResize(event: MediaQueryListEvent) {
			setIsUsingMobileNav(!!event.matches);
		}

		setIsUsingMobileNav(!!mediaQuery.matches);
		mediaQuery.addEventListener("change", onResize);
		return () => mediaQuery.removeEventListener("change", onResize);
	}, []);

	useEffect(() => {
		router.events.on("routeChangeStart", onRouteChangeStart);
		router.events.on("routeChangeComplete", onRouteChangeComplete);

		return () => {
			router.events.off("routeChangeStart", onRouteChangeStart);
			router.events.off("routeChangeComplete", onRouteChangeComplete);
		};
	}, []);

	const currentPath = useRouter().pathname;

	return (
		<div className={styles.nav_header}>
			<h1 className={styles.title}>
				<Link href="/">
					<a href="/" className={styles.branding}>
						<Image alt="Pandora" src={logo} width={218} height={33} />
						<div>Charts</div>
					</a>
				</Link>
			</h1>

			{isUsingMobileNav &&
					<button className={styles.toggle} onClick={onToggle}>
						{isMobileNavOpen
							? <><Icon icon="close" title="close navigation" className={styles.toggle_icon} /><div className={styles.toggle_text}>close nav</div></>
							: <><Icon icon="hamburger" title="open navigation" className={styles.toggle_icon} /><div className={styles.toggle_text}>open nav</div></>
						}
					</button>
			}

			<SiteNav isLoading={isLoading} isUsingMobileNav={isUsingMobileNav} isMobileNavOpen={isMobileNavOpen} closeMobileNav={closeMobileNav}>
				<Link href={Charts.TopThumbHundred.path}>
					<a className={styles.link + (isCurrentPath(Charts.TopThumbHundred.path, currentPath) ? " " + styles.selectedLink : "")}>
						{Charts.TopThumbHundred.name}
					</a>
				</Link>
				<Link href={Charts.Trendsetters.path}>
					<a className={styles.link + (isCurrentPath(Charts.Trendsetters.path, currentPath) ? " " + styles.selectedLink : "")}>
						{Charts.Trendsetters.name}
					</a>
				</Link>
				<Link href={Charts.TopSpins.path}>
					<a className={styles.link + (isCurrentPath(Charts.TopSpins.path, currentPath) ? " " + styles.selectedLink : "")}>
						{Charts.TopSpins.name}
					</a>
				</Link>
			</SiteNav>
		</div>
	);
}

export default NavHeader;