// This file should be used for shared/reused constants.
// This file will likely be used by both the client and server.
// If this grows, feel free to split this up into a `/constants` dir, much like `/services`.

import { ChartKey, Chart, Badge, BadgeTooltip } from "./chart.models";
import { isEmojiSupported } from "is-emoji-supported";

const CHART_ART_DIMENSION = 500;
const CDN_ROOT = "https://content-images.p-cdn.com";

// Use an empty "alt" attribute when an image doesn't provide any real use to a non-visual user.
// Note, this is not the same as a missing alt attribute, bc some assistive devices would resort to reading the file name.
// Ex: when the alt text is similar to existing text that already accompanies an image.
export const EMPTY_ALT_ATTRIBUTE = "";
export const ChartArt = {
	TopThumbHundred: `${CDN_ROOT}/images/16/92/fd/e8/d12e4e66a23395c4457a1ea0/_${CHART_ART_DIMENSION}W_${CHART_ART_DIMENSION}H.jpg`,
	Trendsetters: `${CDN_ROOT}/images/8a/b0/98/cc/46db48528d65141f24eb97cd/_${CHART_ART_DIMENSION}W_${CHART_ART_DIMENSION}H.jpg`,
	TopSpins: `${CDN_ROOT}/images/68/79/0d/6f/55ef46329fe0da11b9814eb2/_${CHART_ART_DIMENSION}W_${CHART_ART_DIMENSION}H.jpg`
};

export const Charts: Record<ChartKey, Chart> = {
	TopThumbHundred: {
		id: "CH:3",
		name: "Top Thumb Hundred",
		path: "/top-thumb-hundred"
	},
	Trendsetters: {
		id: "CH:1",
		name: "Trendsetters",
		path: "/trendsetters"
	},
	TopSpins: {
		id: "CH:2",
		name: "Top Spins",
		path: "/top-spins"
	}
};

export const Badges: Record<string, Badge> = {
	1: {
		name: "HIGHEST CLIMBER",
		emoji: "🚀",
		size: "large",
		class: "highest_climber"
	},
	2: {
		name: "LONGEST STREAK",
		emoji: "🔥",
		size: "large",
		class: "longest_streak"
	},
	3: {
		name: "HOTTEST DEBUT",
		emoji: "🍼",
		size: "large",
		class: "hottest_debut"
	},
	4: {
		name: "FIRST APPEARANCE",
		emoji: "🤩",
		size: "large",
		class: "first_appearance"
	},
	5: {
		name: "PEAK POSITION",
		emoji: "⛰",
		size: "large",
		class: "peak_position"
	},
	6: {
		name: "DEBUT",
		emoji: "🍼",
		size: "small",
		class: "debut"
	},
	7: {
		name: "COMEBACK",
		emoji: (isEmojiSupported("🪃") ? "🪃" : "🔁"),
		size: "small",
		class: "comeback"
	},
	8: {
		name: "HOT STREAK",
		emoji: "🔥",
		size: "small",
		class: "hot_steak"
	}
};

export const BadgeTooltips: Record<string, BadgeTooltip> = {
	"🚀HIGHEST CLIMBER": {
		text: "the biggest jump in position this week",
	},
	"🔥LONGEST STREAK": {
		text: "the longest-running streak of appearances increasing in rank",
	},
	"🍼HOTTEST DEBUT": {
		text: "the highest-ranked appearance in its first week",
	},
	"🤩FIRST APPEARANCE": {
		text: "first appearance on the chart for this artist",
	},
	"⛰PEAK POSITION": {
		text: "highest ever position for this track",
	},
	"🍼DEBUT": {
		text: "first appearance on this chart for this track",
	},
	"🪃COMEBACK": {
		text: "back on the chart after falling off a previous week",
	},
	"🔁COMEBACK": {
		text: "back on the chart after falling off a previous week",
	},
	"🔥HOT STREAK": {
		text: "rising up the ranks for multiple consecutive weeks",
	}
};