import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";

import { isCurrentPath } from "../services/server/util.service";
import "../styles/globals/index.scss";

import NavHeader from "../components/nav-header/nav-header.component";
import Footer from "../components/footer/footer.component";
import LegalFooter from "../components/legal-footer/legal-footer.component";
import { Chart } from "../chart.models";
import { Charts } from "../chart.constants";
import styles from "./_app.module.scss";

function ExternalSite({ Component, pageProps }: AppProps) {
	const router = useRouter();

	function getChartTitle(): string {
		const chart = Object.values(Charts).find((chart: Chart) => isCurrentPath(chart.path, router.pathname));
		const name = (chart) ? chart.name + " | " : "";
		return name;
	}

	return (
		<>
			{/* <!-- OneTrust Cookies Consent Notice start for pandoracharts.com --> */}
			<Script type="text/javascript" src="https://cdn.cookielaw.org/consent/0ee8cfe3-afec-4808-9bc6-3d35c37391e6/OtAutoBlock.js"/>
			<Script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charSet="UTF-8" data-domain-script="0ee8cfe3-afec-4808-9bc6-3d35c37391e6"/>
			<Script id="cookie-banner" type="text/javascript">
				{`
					function OptanonWrapper() { }
				`}
			</Script>
			{/* <!-- OneTrust Cookies Consent Notice end for pandoracharts.com --> */}

			{/* uncomment the below block of code to test cookies banner on staging (and comment out the prod banner above!) */}
			{/* <!-- OneTrust Cookies Consent Notice start for staging.pandoracharts.docker.savagebeast.com --> */}
			{/* <Script src="https://cdn.cookielaw.org/consent/b75c3b29-4b91-4c1c-8c45-b1674228413d/OtAutoBlock.js"/>
			<Script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charSet="UTF-8" data-domain-script="b75c3b29-4b91-4c1c-8c45-b1674228413d"/>
			<Script id="cookie-banner" type="text/javascript">
				{`
					function OptanonWrapper() { }
				`}
			</Script> */}
			{/* <!-- OneTrust Cookies Consent Notice end for staging.pandoracharts.docker.savagebeast.com --> */}

			<Script id="default-consent">
				{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}

					gtag('consent', 'default', {
						'ad_storage': 'denied',
						'analytics_storage': 'denied'
					});
				`}
			</Script>

			<Script id="analytics" strategy="afterInteractive" src={"https://www.googletagmanager.com/gtag/js?id=G-EN1XLNV9QG"} />
			<Script id="analytics-data-layer" strategy="afterInteractive">
				{`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-EN1XLNV9QG', {
                    	page_path: window.location.pathname,
                    });
                `}
			</Script>

			<Head>
				<title>{getChartTitle()}Pandora Charts</title>
				<meta name="twitter:card" content="summary_large_image"></meta>
				<meta name="twitter:site" content="@pandoramusic"></meta>
			</Head>
			<div className={styles.header}>
				<NavHeader />
			</div>
			<div className={styles.main}>
				<Component {...pageProps} />
			</div>
			<div className={styles.footer}>
				<Footer />
			</div>
			<div>
				<LegalFooter />
			</div>
		</>
	);
}

export default ExternalSite;
