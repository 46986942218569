import Link from "next/link";
import styles from "./legal-footer.module.scss";

// note this is separate from Footer because it needs to be centered horizontally on the page
// whereas Footer has a fixed width
function LegalFooter() {
	return (
		<div className={styles.footer}>
			<div className={styles.link_container}>
				<Link href="https://www.pandora.com/privacy"><a className={styles.link}>Privacy</a></Link>
				<Link href="https://www.pandora.com/legal"><a className={styles.link}>Terms</a></Link>
				<a className={styles.link + " ot-sdk-show-settings"}>Cookie Settings</a>
				<Link href="https://privacyportal-cdn.onetrust.com/dsarwebform/613d75ce-3c5b-4623-bcef-a0d3694b4214/56137b15-e2e8-4928-a490-47f33499069e.html"><a className={styles.link}>Do Not Sell My Personal Information</a></Link>
			</div>
			<div className={styles.copyright}>
				&copy; 2022 Pandora Media, LLC. All rights reserved.
			</div>
		</div>
	);
}

export default LegalFooter;
