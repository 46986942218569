import Image from "next/image";
import Link from "next/link";
import styles from "./footer.module.scss";
import { useRouter } from "next/router";
import { Charts, ChartArt, EMPTY_ALT_ATTRIBUTE } from "../../chart.constants";
import { isCurrentPath } from "../../services/server/util.service";

function Footer() {
	const currentPath = useRouter().pathname;

	return (
		<div className={styles.footer}>
			<h3 className={styles.title}>More Pandora Charts</h3>
			<nav className={styles.inner_container}>
				{!isCurrentPath(Charts.TopSpins.path, currentPath) && <Link href={Charts.TopSpins.path}><a
					className={styles.link}
				>
					<Image
						alt={EMPTY_ALT_ATTRIBUTE}
						src={ChartArt.TopSpins}
						width={182}
						height={182}
						unoptimized
					/>
					<span className={styles.name}>{Charts.TopSpins.name}</span>
				</a></Link>}
				{!isCurrentPath(Charts.Trendsetters.path, currentPath) && <Link href={Charts.Trendsetters.path}><a
					className={styles.link}
				>
					<Image
						alt={EMPTY_ALT_ATTRIBUTE}
						src={ChartArt.Trendsetters}
						width={182}
						height={182}
						unoptimized
					/>
					<span className={styles.name}>{Charts.Trendsetters.name}</span>
				</a></Link>}
				{!isCurrentPath(Charts.TopThumbHundred.path, currentPath) && <Link href={Charts.TopThumbHundred.path}><a
					className={styles.link}
				>
					<Image
						alt={EMPTY_ALT_ATTRIBUTE}
						src={ChartArt.TopThumbHundred}
						width={182}
						height={182}
						unoptimized
					/>
					<span className={styles.name}>{Charts.TopThumbHundred.name}</span>
				</a></Link>}
			</nav>
		</div>
	);
}

export default Footer;