import cn from "clsx";
import { slide as SlideMenu } from "react-burger-menu";
import styles from "./site-nav.module.scss";

export interface NavPanelProps {
	isLoading: boolean;
	isUsingMobileNav: boolean;
	isMobileNavOpen: boolean;
	closeMobileNav(): void;
	children: React.ReactNode;
}

export function SiteNav({ isLoading, isUsingMobileNav, isMobileNavOpen, closeMobileNav, children }: NavPanelProps) {
	if (isUsingMobileNav) {
		return (
			<div className={cn(styles.site_nav, styles.site_nav__mobile)}>
				<SlideMenu
					right={true}
					noTransition={true}
					noOverlay={true}
					isOpen={isMobileNavOpen}
					width={"100vw"}
					customBurgerIcon={false}
					customCrossIcon={false}
					menuClassName={styles.menu}
					itemListClassName={styles.itemList}
					onClose={closeMobileNav}
				>
					{isLoading ? <div className={styles.loading}>
						<span className={styles.loadingCopy}>loading...</span>
					</div> : children}
				</SlideMenu>
			</div>
		);
	}

	return (
		<nav className={cn(styles.site_nav, styles.site_nav__expanded)}>{children}</nav>
	);
}

export default SiteNav;
